<template>
  <div class="index">
    <div>
      <!-- banner -->
      <el-carousel height="450px" class="indexBanner">
        <el-carousel-item v-for="(item, index) in $store.getters.banner" :key="index">
          <img :src="item.pic" alt="" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
      <!-- 公告 -->
      <el-carousel height="50px" direction="vertical" class="indexNotice">
        <el-carousel-item v-for="(item, index) in $store.getters.rollList" :key="index">
          <h3 class="medium">
            <span>网站公告：</span> <span>{{ item.title }}</span>
            <span>{{ item.createTime }}</span>
            <span @click="handleCommandAS" style=" cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span>
            
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="breadcrumb">
      <div>首页</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <video :src="require('@/assets/video/logo.mp4')" controls width="100%" autoplay></video>

      <div class="price">
        <el-row class="priceBox">
          <el-col :span="8" class="priceBoxList">
            <h2>2005</h2>
            <p>公司成立</p>
          </el-col>
          <el-col :span="8" class="priceBoxList">
            <h2>70+</h2>
            <p>拥有专利</p>
          </el-col>
          <el-col :span="8" class="priceBoxList">
            <h2>600+亩</h2>
            <p>拥有土地</p>
          </el-col>
        </el-row>
        <el-row class="info">
          <el-col :span="12" class="infoList">
            <p>
              昱升集团成立于2005年，主要研发、生产、销售中高端婴幼儿、成人护理用品。经过十六年的发展，
              已拥有50万平方的先进生产基地，占地六百多亩，成为几千名员工的大型集团企业，也是国内全伺服瑞光机保有量最多的纸尿裤生产企业。
              集团建立了独立的研发中心，专注研发更优质的个人护理用品。是中国个人护理用品的领导企业。
              上海可柔科技有限公司是专为推广keroo品牌设立的全资子公司
            </p>
          </el-col>
          <el-col :span="12" class="infoImg">
            <img :src="require('@/assets/logo/home1.jpg')" alt="" />
          </el-col>
        </el-row>
        <el-row class="info">
          <el-col :span="12" class="infoImg">
            <img :src="require('@/assets/logo/home2.jpg')" alt="" />
          </el-col>
          <el-col :span="12" class="infoList">
            <p>
              公司秉承“品质是公司持续坚持自主研发创新，不断加大产品的研发投入。公司拥有近40项专利，
              具备较强的自主创新及研发能力，是广东省工程技术研究开发中心、佛山市工程技术研究开发中心、
              南海区工程技术研究开发中心、佛山市市级企业技术中心和国家高新技术企业。
            </p>
          </el-col>
        </el-row>
        <el-row class="info">
          <el-col :span="12" class="infoList">
            <p>
              公司产品线丰富，从传统型的婴儿纸尿裤、片，成人纸尿裤、片，护理垫，到现代型的婴儿拉拉裤，环抱型的婴儿纸尿裤。
            </p>
            <p>公司秉承“企业的生命，品质源于管理”的企业方针，严格遵行ISO9001质量管理体系，与多家国际知名企业合作，
              结为战略合作伙伴，确保技术的领先和优质的原材料供应。公司采用严苛的企业标准，严格执行原材料及成品检验，确保产品质量。</p>
          </el-col>
          <el-col :span="12" class="infoImg">
            <img :src="require('@/assets/logo/home3.jpg')" alt="" />
          </el-col>
        </el-row>
      </div>
      <!-- 公司发展历程 -->
      <div class="history">
            <div class="historyItem" v-for="(item, index) in historyList" :key="index">
              
              <div class="dot">
                <span></span>
                <div class="dotD"><span class="dotDS"></span></div>
                <span></span>
              </div>
              <div class="infos">
                <p v-if="index%2==1">{{ item.text }}</p>
                <h2>{{ item.year }}</h2>
                <p v-if="index%2==0">{{ item.text }}</p>
              </div>
            </div>
      </div>
      <!-- 外链接 -->
      <div class="outlink">
        <div class="outlinkItem">
         
          <img @click="toDouyin" :src="require('@/assets/video/douyin.jpg')" alt="">
           <p>抖音旗舰店</p>
        </div>
        <div class="outlinkItem">
          
          <img @click="toKuaishou" :src="require('@/assets/video/kuaishou.jpg')" alt="">
          <p>快手旗舰店</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {aoiGetUserinfo} from '@/api/login'
import {apiGetArticleByType} from '@/api/arcticle'

export default {
  name: "homePage",
  data() {
    return {
      curIndex: 0,
      historyList: [
        {
          img: require("@/assets/logo/banner1.jpg"),
          year: "2005",
          text:
            `2005年 耗资4800多万，注册成立"佛山南海昱升卫生用品有限公司"，占地5000平方米。`
        },
        {
          img: require("@/assets/logo/banner2.jpg"),
          year: "2008",
          text:
            `2008年 规模扩大，厂址搬迁至佛山穆院，占地面积近3万平方米。创建"吉氏"品牌，全面开拓国内婴童市场。`
        },
        {
          img: require("@/assets/logo/banner3.jpg"),
          year: "2010",
          text:
            `2010年 搬迁至狮山小塘新城工业区。荣获"行业十大创新品牌"荣誉。`
        },
        {
          img: require("@/assets/logo/banner4.jpg"),
          year: "2011",
          text:
            `2011年 更名为广东昱升卫生用品实业有限公司。搬迁至佛山狮山新城工业区,四个生产基地同时扩建，
            拥有6万多平方米的现代化工业园。荣获"重质量品牌企业"荣誉。`
        },
        {
          img: require("@/assets/logo/banner5.jpg"),
          year: "2015",
          text:
           `2015年 更名为广东昱升个人护理用品股份有限公司。创立昱升个人护理用品研发中心。成为超千万元纳税大户。 被认定为"高新技术企业"。`
        },
        {
          img: require("@/assets/logo/banner6.jpg"),
          year: "2017",
          text:
            `2017年 荣获"广东省名牌产品" "南海制造业全国隐形冠军" "广东省高新科技产品"等荣誉。`
        },
        {
          img: require("@/assets/logo/banner7.jpg"),
          year: "2018",
          text:
            `2018年 浙江、湖南分工厂生产基地落成； 荣膺“中国婴儿纸尿裤行业10强企业”；
             荣获首批“佛山市标杆高新技术企业50强”； 入选“佛山市首批重点知识产权保护名录”`
        },
        {
          img: require("@/assets/logo/banner8.jpg"),
          year: "2019",
          text:
            `2019年 正式推出新零售高端领导母婴品牌 keroo纸尿裤。产品引进法国奢侈品制造工艺，
            针对中国婴幼儿成长特点研发，给宝宝提供更安全、更优质、更舒适的呵护。`
        },
      ],
    };
  },
  mounted(){
    this.getUserInfo();
    this.getArticleList()
    if(this.$store.state.menuIndex == 'account'){
        this.$router.replace({name: localStorage.getItem('accountIndex')?localStorage.getItem('accountIndex'):'account'})
    }else{
        this.$router.replace({name: this.$store.state.menuIndex})
    }
    
  },
  methods: {
    toDouyin(){
       window.open('https://haohuo.jinritemai.com/views/shop/index?id=qdTirJBe&origin_type','_blank')
    },
    toKuaishou(){
      window.open('https://app.kwaixiaodian.com/merchant/shop/list?id=748648786','_blank')
      // window.location.herf = 'https://app.kwaixiaodian.com/merchant/shop/list?id=748648786'
    },
    handleCommandAS(){
      this.$router.push({name: 'joiningNotice'})
      this.$store.commit('SET_INFO',['menuIndex','joining'])
    },
       //获取用户信息
    getUserInfo() {
    
      aoiGetUserinfo().then(res=>{
        if(res.code == 200){
          console.log(res);
          this.$store.commit("SET_INFO", ["userInfo", res.data.data]);
        }
      })
    },
    getArticleList(){
      apiGetArticleByType({cid: 533}).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.brandlist = res.data.list
          this.$store.commit('SET_INFO',['rollList',res.data.list])
        }
      })
    },
    handleCar(index) {
      this.curIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
 
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
}
.indexBanner,
.indexNotice,
.container {
  background-color: #fff;
  margin-bottom: 10px;
}
.breadcrumb {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  border-bottom: 3px solid#f1f2f3;
}
.container {
  //    padding: 0 10px;
  &Img {
    height: 600px;
    width: 100%;
    display: block;
    border-bottom: 10px solid#f5f6f7;
  }
  .price {
    &BoxList {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 200px;
      padding-top: 65px;
      h2 {
        text-align: center;
        font-size: 30px;
        line-height: 40px;
        color: red;
      }
      p {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: #999;
      }
    }
  }
  .info{
    margin-bottom: 15px;
  }
  .infoList {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .infoList p {
    text-indent: 30px;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 15px;
  }
  .infoImg {
    height: 360px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 80px;
}
//
.history {
  border-top: 10px solid #f5f6f7;
  position: relative;
  height: 750px;
  background: url('./../assets/logo/di.jpg');
  background-size: 100% 100%;
  background-color: rgba($color: #fff, $alpha: 0.6);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  &Item {
    position: relative;
    .dot{
       height: 50px;
       display: flex;
       justify-content: center;
       align-items: center;
       span{
         width: 55px;
         height: 1px;
         background-color: #ccc;
       }
       .dotD{
         width: 24px;
         height:24px;
         border-radius: 50%;
         border: 1px solid #ccc;
         display: flex;
         align-items: center;
         justify-content: center;
         .dotDS{
           width: 16px;
           height: 16px;
           border-radius: 50%;
           background-color:#666;
         }
       }
    }
    .infos{
      position: absolute;
      left: -40px;
      top: 60px;
      right: -40px;
      height: 170px;
      &:hover{
        h2{
           background-color: skyblue;
        }
        p{
          background-color: rgba(255,255,255,0.3);
           color: #000;
        }
      }
      h2{
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        background-color: rgba(0,0,0,0.1);
       
      }
      p{
        height: 160px;
        line-height: 26px;
        font-size: 14px;
        background-color: rgba(0,0,0,0.2);
        color: #fff;
        padding: 5px;
      }
    }
    &:nth-child(2n){
      .infos{
      top: -220px;
      }
    }
  }
}
.outlink{
  padding: 20px;
  display: flex;
  justify-content: center;
  .outlinkItem{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 150px;
    img{
      width: 150px;
      height: 150px;
    }
  }
}
</style>